* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in _config.scss
html {
  background: $light;
  box-sizing: border-box;
  color: $dark;
  font-family: $mainFont;
  font-size: 100% * ($baseFontSize / 16px);
  hyphens: manual;
  line-height: rem($baseLineHeight);
  scroll-behavior: smooth;

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;
  overflow-anchor: none;
}

body {
  line-height: rem($baseLineHeight);
  opacity: 1 !important;
}

iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

address {
  font-style:normal;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend .marginBottom;
  display: block;
  text-rendering: optimizeLegibility;
  hyphens: auto;

  /* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
}

h1,
 .h1 {
  font-family: $displayFont;
  text-transform: uppercase;
  font-size: rem(26px);
  line-height: 1.3em;
  font-weight: 700;

  @include breakpoint(tiny) {font-size: rem(28px);}
  @include breakpoint(medium) {font-size: rem(32px);}

  @include breakpoint(large) {
    font-size: rem($h1Size);
    line-height: 1.1em;
  }
  
  span {
    font-size: rem(16px);
    text-transform: none;
    display: block;
    font-family: $spanFont;
    color: $border;
    line-height: 1.6em;
    margin-top: 0.5rem;
    @include breakpoint(tiny) {font-size: rem(18px);}
    @include breakpoint(small) {font-size: rem(20px);}


    @include breakpoint(medium) {
      font-size: rem(22px);
    }
    @include breakpoint(large) {
      font-size: rem(26px);
    }
  } 
 }

 h2,
 .h2 {
  font-family: $displayFont;
  text-transform: uppercase;
  font-size: rem(24px);
  line-height: 1.3em;
  font-weight: 700;
  @include breakpoint(tiny) {font-size: rem(26px);}
  @include breakpoint(medium) {font-size: rem(30px);}

  @include breakpoint(large) {
    font-size: rem($h2Size);
  }
  
  span{
    font-size: rem(16px);
    text-transform: none;
    display: block;
    font-family: $spanFont;
    color: $border;
    line-height: 1.6em;
    margin-top: 0.5rem;
    @include breakpoint(tiny) {font-size: rem(18px);}
    @include breakpoint(small) {font-size: rem(20px);}


    @include breakpoint(large) {
      font-size: rem(22px);
    }
  } 
 }

 h3,
 .h3 {
  font-family: $displayFont;
  text-transform: uppercase;
  font-size: rem(22px);
  line-height: 1.3em;
  font-weight: 700;
  @include breakpoint(medium) {font-size: rem(28px);}

  @include breakpoint(large) {
    font-size: rem($h3Size);
  }
  
  span{
    font-size: rem(16px);
    text-transform: none;
    display: block;
    font-family: $spanFont;
    color: $border;
    line-height: 1.6em;
    margin-top: 0.5rem;
    @include breakpoint(tiny) {font-size: rem(18px);}
    @include breakpoint(small) {font-size: rem(20px);}

  } 
 }

 h4,
 .h4 {

  font-size: rem(23px);
  @include breakpoint(medium) { font-size: rem($h4Size);}

 }

 h5,
 .h5 {
  font-size: rem($h5Size);
 }

 h6,
 .h6 {
  font-size: rem($h6Size);
 }

/**
 * Links
 */
 a {
  color: $secondary;
  word-wrap: break-word;
  transition: 400ms;
  text-decoration: none;

  &:focus,
  &:hover,
  &:active {
    color: lighten($primary, 18%);
  }

  img {
    border: none;
  }

  &[href^="tel"] {
    color: inherit;
    text-decoration: npne;
  }
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

hr,
.hr {
  display: flex;
  background: $medium;
  border: none;
  clear: both;
  height: rem(1px);
  margin: 1rem 0;
  flex-basis: 100%;
}

ul,
ol {
  list-style: none;

  &.styledList {
    li {
      padding-left: rem(15px);
      margin-bottom: rem(7px);

      &:before {
        @extend .icon;
        @extend .icon-angle-two-right;
        text-indent: rem(-15px);
        color: $border;
        font-size: rem(16px);
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

dl {
  &.styledList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      background: rgba($medium, 0.8);
      padding: rem(15px);
    }

    dt {
      padding-bottom: 0;
      font-weight: 700;
    }

    dd {
      padding-top: 0;

      + dt {
        margin-top: rem(10px);
      }

      + dd {
        margin-top: rem(-15px);
      }
    }
  }

  &.floatList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }
}

ol {
  &.styledList
  {
    counter-reset: item;
  
    li {
      counter-increment: item;
  
      &:before {
        content: counters(item, ".") ". ";
      }
    }
  
    ol {
      margin-bottom: 0;
  
      li {
        &:before {
          content: counters(item, ".") " ";
          margin-right: rem(5px);
        }
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'], &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom: 1.5rem;
  }

  &[data-src] {
    @extend %lazyloading;
  }
}

/**
 * Tables
 */
main {
  table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: rem($baseFontSize);

    @include breakpoint(small, max) {
      overflow-x: auto;
      display: block;
    }

    caption {
      text-align: left;
      padding: rem($baseGap) rem($baseGap) rem(25px);
      display: table-caption;
      font-weight: 700;
      font-size: 1.2rem;
    }

    thead {
      border-collapse: collapse;
      display: table-header-group;
    }

    tbody {
      width: 100%;
      overflow-x: scroll;
      display: table-row-group;

      tr {
        padding: rem($baseGap) 0;

        &:not(:last-child) {
          border-bottom: rem(1px) solid $medium;
        }
      }
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: rem($baseGap);
      display: table-cell;
      vertical-align: top;
    }

    @include breakpoint(small, max) {
      td {
        min-width: 50vw;
      }
    }
  }
}

.two-columns {
  @include breakpoint(medium) {
    column-count: 2;
  }
}

.padding-right {@include breakpoint(large) {padding-right: $padding-small;}}
.padding-left {@include breakpoint(large) {padding-left: $padding-small;}}

.padding-top {
  padding-top: rem(30px);
  
  @include breakpoint(large) {
    padding-top: $padding-small;
  }
}

.only-large {
  @include breakpoint(large, max) {
    display: none;
  }

  img {
    margin-bottom: rem(23px);
  }
}


.frame {
  margin-bottom: 36px;
  padding: 3.22222rem;
  padding: 12.5%;
  background-color: #fff;
  border: 1px solid #dedede;
}


.specialBox {
  background: $medium;
  text-align: center;
  padding: rem(20px);

  p.noMarg {
    margin-bottom: 0;
  }

  &.fullHeight {
    height: 100%;
    @include breakpoint(medium, max) {
      margin-bottom: $baseGap;
    }
  }
}


.margBottom-medium {
  @include breakpoint(medium, max) {
    margin-bottom: $baseGap;
  }
}

strong, b{
  font-weight: 700;
}