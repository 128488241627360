
// HEADER
//////////////////////////////

.mobileBranding {
	margin-top: rem(50px);
	display: flex;
	justify-content: center;
	background: $primary;
	width: 100%;

	@include breakpoint(giant) {
		display: none;
	}

	img {
		max-width: rem(370px);
	}
}

#header {
	position: relative;
	width: 100%;
	height:420px;

	@include breakpoint(giant) {
		margin-top: rem(100px);
	}

	@include breakpoint(huge) {
		margin-top: rem(125px);
	}

	// body:not(.index) & {
	// 	margin-bottom: $padding-small;
	// 	@include breakpoint(medium) {
	// 		margin-bottom: $padding;
	// 	}
	// }

	.bgHeader {
		padding: 3rem 0 4rem;
		color: $light;
		background: rgba($color: #000000, $alpha: 0.3);
		height: 420px;

		@media only screen and (max-width: 600px) {
			background: url(../images/layout/tiny-bgHeader.jpg);
			background-size: cover;
			background-position: center;
		}

		@media only screen and (max-width: 859px) {
			// background: url(../images/layout/tiny-bgHeader.jpg);
		}
		
		@include breakpoint(medium) {
			background-position: center;
			padding: 2rem 0 3rem;
			//background: url(../images/layout/medium-bgHeader.jpg);
			background-size: cover;
			background-position: center;
		}

		@include breakpoint(giant) {
			padding: 3rem 0 4rem;
			//background: url(../images/layout/bgHeader.jpg);
			background-size: cover;
			background-position: center;
		}

		/**body.index & {
			padding: 3rem 0;
			@include breakpoint(small) {padding: 5rem 0;}
			@include breakpoint(medium) {padding: 6rem 0;}
		}**/

		// .claim {
		// 	max-width: rem(800px);

		// 	body:not(.index) & {
		// 		max-width: 100%;
		// 	}
		// }
		
		.blockquote {
			p {
				font-size: rem(30px);
				line-height: 1.3em;

				@include breakpoint(tiny) {
					font-size: rem(32px);
				}

				@include breakpoint(small) {
					font-size: rem(34px);
				}

				@include breakpoint(medium) {
					font-size: rem(38px);
				}

				@include breakpoint(huge) {
					font-size: rem(50px);

					body:not(.index) & {
						font-size: rem(45px);
				}
			}
				span {
					font-size: rem(20px);
					color: $light;
					margin-top: 0;

					@include breakpoint(huge) {
						font-size: rem(33px);
					}
				}
			}
		}

		ul {
			li {
				padding-left: rem(15px);

				&:before {
					@extend .icon;
					@extend .icon-angle-two-right;
					color: $secondary;
					text-indent: rem(-15px);
				}
			}
		}

		.btn {
			body.index & {
				margin-top: rem(20px);
				display: inline-block;
			}
		}
	}
	#video_background {
	object-fit: cover;
	object-position: center;
	position: absolute;
	top: 0px;
	right: 0px;
	min-width: 100%;
	min-height: 100%;
	width: 100%;
	height: 100%;
	z-index: -1000;
	overflow: hidden;
	@media only screen and (max-width: 859px) {
		background: url(../images/layout/tiny-bgHeader.jpg);
		display: none;
	}
}
#video_pattern {
	position: fixed;
	opacity: 0.5;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: -1;
}
}

.highlightSection {
	@include responsiveImage('layout/highlightBg.jpg', (tiny), true);
	padding: $padding-small 0;
	
	&.lazyLoaded {
		background-size:cover
	}

	@include breakpoint(medium) {
		padding: $padding 0;
	}
	
	h2, .h2{
		color: $light;

		span {
			color: $light;
		}

		@include breakpoint(medium) {
			text-align: center;
		}
	}

	p {
		margin-bottom: 0;
	}

	.whiteBox {
		color: $light;

		ul {
			margin-bottom: 0;
		}

		@include breakpoint(medium) {
			margin: 0 30px;
			background: $light;
			padding: $baseGap * 2;
			color: $dark;
		}

		@include breakpoint(large) {
			padding: $padding-small;
		}
	}
}

.serviceSection {
	padding: $padding-small 0;
	
	@include responsiveImage('/images/layout/serviceBg.jpg', (medium), true);
	background-size: cover;
	background-position: center;

	@include breakpoint(medium) {
		padding: $padding 0;
	}

	.claim {
		text-align: center;

		@include breakpoint(medium) {
			max-width: rem(687px);
			margin: 0 auto;
			
			p.h3 {
				font-size: rem(40px);
			}
		}
	}

}

// MAIN CONTENT
//////////////////////////////

// padding
.firstSection, .secondSection, .teaserSection {
	padding: $padding-small 0;
	@include breakpoint(medium) {
		padding: $padding 0;
	}
}

main {
	display: block;
	
	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	.block {
		display:block !important;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}

	.teaserSection {
		background: $medium;

		.row {
			.col {
				&.teaser3 {
					@include breakpoint(tiny) {
						margin-left: 25%;
					}

					@include breakpoint(medium) {
						margin-left: 0;
					}
				}
				&:first-child {
					div {
						margin-bottom: rem(50px);

						@include breakpoint(tiny) {
							margin-bottom: 0;
						}
					}
				}

				&:last-child {
					figure {
						margin-top: rem(50px);

						@include breakpoint(medium) {
							margin-top: 0;
						}
					}
				}

				figure {
					display: flex;
					justify-content: center;

					img {
						max-width: rem(320px);

						@include breakpoint(giant) {
							max-width: rem(447px);
						}
					}
				}

				div {
					display: flex;
					justify-content: center;

					a {
						text-transform: uppercase;
						color: $dark;
						font-size: rem(32px);
						line-height: rem(42px);
						position: relative;

						@include breakpoint(tiny) {
							font-size: rem(22px);
							line-height: rem(32px);
						}

						@include breakpoint(giant) {
							font-size: rem(32px);
							line-height: rem(42px);
						}

						&:after {
							@extend .icon;
							content: '\ea3c';
							margin-left: rem(10px);
							display: inline-block;
							font-style: normal;
							transition: 300ms all ease-in-out;
						}

						&:hover {
							color: $primary;

							&:after {
								color: $border;
								margin-left: rem(15px);
							}
						}
					}
				}
			}
		}
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	iframe {
		height: rem(400px);

		@include breakpoint(medium) {
			height: rem(550px);
		}
	}

	.footerContacts {
		text-align:center;
		@include breakpoint(medium) {
			padding: rem(20px) 0;
		}

		@include breakpoint(giant) {
			padding: rem(20px) 0 rem(10px);
		}
	}

	.mb {
		margin-bottom: rem($baseGap);
	}

	dl {
		dt {
			width: rem(80px);
			display: inline-block;
		}

		dd {
			width: calc(100% - 90px);
			display: inline-flex;
			@include breakpoint(medium) {
				display: inline-block;
			}
		}
	}

	.fb {
		display: flex;
		align-items: center;
		justify-content: center;

	

		figure {
			margin-right: rem(15px);
			width: rem(68px);
		}
	}

	.hr {
		background: $light;

		@include breakpoint(medium) {
			background: $medium;
		}
	}

	.naviAdd {
		margin-bottom: rem($baseGap);
		padding: rem(15px) 0;
		display: flex;
		flex-direction: column;

		@include breakpoint(medium) {
			flex-direction: row;
			padding: rem(5px) 0;
			justify-content: center;
		}

		li {
			border-top: rem(1px) solid $border;
			width: 100%;
			margin-right: 0;

			@include breakpoint(medium) {
				border-top: 0;
				width: auto;
			}
			
			a {
				width: 100%;
				padding: rem(15px) 0;
				display: block;
				text-align: center;
				text-transform: uppercase;
				color: $dark;
				font-size: rem(16px);

				@include breakpoint(medium) {
					padding: rem(15px) rem(20px);
				}

				&:hover, &.active {
					color: $border;
				}
			}
		}
	}
}

.gap-top{
	margin-top: 10px;

	@include breakpoint(medium) {
		margin-top: 20px;
	}

	@include breakpoint(large) {
		margin-top: 50px;
	}

	@include breakpoint(giant) {
		margin-top: 100px;
	}
}
.facebook {
	display: flex;
	align-items: center;
	justify-content: center;
	
	
	
	img {
		margin-right: rem(15px);
		width: rem(22px);
	}
}
.instagram {
	margin: 0 .83333rem;
	display: flex;
	align-items: center;
	justify-content: center;



	img {
		margin-right: rem(15px);
		width: rem(22px);
	}
}