// Use this for default Icomoon

@include fontFaceV2(
 $fontName: "icomoon",
 $fileName: "icomoon",
 $types: (woff, ttf, svg, eot),
 $style: normal,
);

@include fontFaceV2(
    $fontName: "Lora", 
    $fileName: "lora-v14-latin", 
    $weights: ("400"), 
    $types: all
);

@include fontFaceV2(
    $fontName: "Roboto Condensed", 
    $fileName: "roboto-condensed-v18-latin", 
    $weights: ("400", "700", "400|italic"), 
    $types: all
);