// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: giant; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: .7em rem($baseGap);
	text-decoration: none; 
	color: $navColor;

	@include breakpoint($break) {
		padding: 1em rem(8px);
		font-size: rem(17px);
	}

	@include breakpoint(huge) {
		font-size: rem(18px);
		padding: 1em rem(15px);
	}
}
%buttonHover {
	// Link Hover
	color: $light;
	background-color: $primary;

	@include breakpoint($break) {
		background: transparent;
		color: $primary;
	}
}
%buttonActive {
	// Link Active
	color: $light;
	background-color: $primary;

	@include breakpoint($break) {
		background: transparent;
		color: $border;
	}
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	color: $border;
	font-size: rem(16px);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	.row {
		.col {
			padding: 0;

			.branding {
				display: none;
			}

			.side {
				.contacts {
					display: none;
				}
			}
		}
	}

	nav {
		@include ul {
			&.navi {
				li {
					&.standort{
						margin-bottom: 200px;
					}
					@include navButtons {
						@include navButton;
					}
					@include has-sub {
						@include sub {
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
						color: #b9b9b9;
						font-size: 16px;
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);

		.row {
			.col {
				padding-right: rem($baseGap);
				display: flex;
				justify-content: space-between;
				align-items: center;

				@include breakpoint(cut) {
					padding-left: rem($baseGap);
				}

				.branding {
					max-width: rem(370px);
					display: inline-block;
					position: relative;
					position: relative;

					&:before {
						@include breakpoint(cut) {
							content: '';
							height: 100%;
							width: 50vw;
							position: absolute;
							top: 0;
							right: 100%;
							z-index: 10;
							background: $primary;
						}
					}

					&:after {
						@include breakpoint(giant) {
							content: '';
							height: 100%;
							width: 5px;
							position: absolute;
							top: 0;
							left: 100%;
							z-index: 10;
							background: $primary;
						}

						@include breakpoint(huge) {
							width: rem(10px);
						}

						@include breakpoint(full) {
							width: rem(20px);
						}
					}
				}

				.side {
					.contacts {
						display: inline-block;
						float: right;
						margin-top: 1em;
						display: flex;

						.openTiming {
							&:before {
								font-family: $iconFont;
								content: '\e94f';
								margin-right: 8px;
							}
						}

						.phone {
							color: $primary;
							font-weight: bold;
							margin-left: rem(40px);

							@include breakpoint(huge) {
								margin-left: rem(60px);
							}

							&:before {
								font-family: $iconFont;
								content: '\e942';
								margin-right: 8px;
							}
						}

						.email {
							margin: 0 rem(8px) 0 rem(40px);

							@include breakpoint(huge) {
								margin: 0 rem(15px) 0 rem(60px);
							}

							&:before {
								font-family: $iconFont;
								content: '\f1d8';
								margin-right: 8px;
							}
						}
					}
				}
			}
		}

		nav {
			ul {
				&.navi {
					li {
						&.standort{
							margin-bottom: 0;
						}
						span {
							transition: 300ms all ease-in-out;
						}
						&.has-sub {
							.sub {
								box-shadow: 0px 0px 25px 0px #d0d0d0;
								li {
									&.airboxen-bmw-m6, &.bodykits-aston-martin-vantage{
										margin-bottom: 0;
									}
									a, span {
										padding: 0.5em rem(25px);
										transition: 300ms all ease-in-out;

										&:hover, &.active {
											background: $primary;
											color: $light;
										}
									}
									span{
										&:after{
											transform:rotate(0deg);
										}
									}
								}
							}
						}
						&.navBack {
							padding: 0;

							&:before {
								transition: 300ms all ease-in-out;
							}
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}

	.branding {
		width: rem(175px);
		display: block;
	}

	.phone{
		&:before {
			font-family: $iconFont;
			content: '\e942';
			margin-right: 8px;
		}
	}
}

//////////////////////////////////////////////
// sitemap
//////////////////////////////////////////////
body.sitemap {
	.sub {
		padding-left: rem(20px);
		list-style: circle;
	}
}

ul.navi {
	li {
		&:last-of-type {
			// padding-bottom: 5rem;
			// @include breakpoint(giant) {
			// 	padding-bottom: 0;
			// }
		}
	}
}